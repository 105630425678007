module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Torie Jee Portfolio","short_name":"Torie Jee","start_url":"/","display":"standalone","lang":"en","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"description":"From reading scripts to developing behind-the-scenes content for theatrical titles, my world revolves around storytelling. In my past role at 20th Century Fox, I worked alongside producers and filmmakers to create content pieces that told the stories of how their film came to be, uncovering the secrets and details that made their movies truly special. In my current position advertising for ABC Entertainment and The Walt Disney Studios at OMG23, I've become well-versed in working with publishers to create short-form content that can draw in viewers in less than thirty seconds! Whether it's action-packed stunt pieces or funny bite-sized videos, I strive to make each piece of content I work on an exciting and new viewing experience!","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f77a8ee9bf92d62fc643af0062f47239"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
